import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import About from "./Components/About";
import CardDetails from "./Components/CardDetails";
import Blogs from "./Components/Blogs";
import Policy from "./Components/Policy";
import Services from "./Components/Services";
import Contact from "./pages/contact/Contact";
import Verification from "./Components/Verification";
import Header from "./Components/Header";
import Admin from "./Components/Admin"; // Updated import
import Login from "./Components/Login";

// animation part import
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import CertificateSearch from "./Components/CertificateSearch";
import Training from "./Components/Training";
import Accredation from "./Components/Accredation";
import ProtectedRoute from "./ProtectedRoute";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div id="header">
        <Header />
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="blog" element={<Blogs />} />
        <Route path="services" element={<Services />} />
        <Route path="/details/:id" element={<CardDetails />} />
        <Route path="policy" element={<Policy />} />
        <Route path="contact" element={<Contact />} />
        <Route path="verification" element={<Verification />} />
        <Route path="CertificateSearch" element={<CertificateSearch />} />
        <Route path="Training" element={<Training />} />
        <Route path="Accredation" element={<Accredation />} />
        <Route path="login" element={<Login />} />
        <Route
          path="admin"
          element={
            <ProtectedRoute>
              <Admin />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}

export default App;
