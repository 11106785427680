// ProtectedRoute.jsx
import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem("adminToken");
  const API_URL = "https://api.dascertificationgroup.com"; // Use environment variable

  useEffect(() => {
    const verifyToken = async () => {
      if (!token) {
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(`${API_URL}/api/admin/verify`, {
          // Fixed URL path
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          setIsVerified(true);
        } else {
          // Clear invalid token
          localStorage.removeItem("adminToken");
          localStorage.removeItem("adminEmail"); // Changed from username to email
        }
      } catch (error) {
        console.error("Token verification error:", error);
        localStorage.removeItem("adminToken");
        localStorage.removeItem("adminEmail"); // Changed from username to email
      } finally {
        setIsLoading(false);
      }
    };

    verifyToken();
  }, [token, API_URL]); // Added API_URL to dependencies

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (!token || !isVerified) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
