import React, { useState } from "react";
import "../styles/CertificateSearch.css";

const CertificateSearch = () => {
  const [certificateNumber, setCertificateNumber] = useState("");
  const [searchResult, setSearchResult] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [loading, setLoading] = useState(false);
  const API_URL =
    process.env.REACT_APP_API_URL || "https://api.dascertificationgroup.com";

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleSearch = async () => {
    if (!certificateNumber.trim()) return;

    setHasSearched(true);
    setLoading(true);

    try {
      const response = await fetch(
        `${API_URL}/api/certificates/${certificateNumber}`
      );
      const data = await response.json();

      if (response.ok) {
        setSearchResult(data);
      } else {
        setSearchResult(null);
      }
    } catch (error) {
      console.error("Search error:", error);
      setSearchResult(null);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setCertificateNumber(e.target.value);
    setHasSearched(false);
  };

  return (
    <div className="TopMeanVerificationHeading">
      <div className="meanVerificationHeading">
        <div className="heading">
          <h2 className="searchHeading">Certificate Search</h2>
          <p className="searchPara">
            You may verify all management system certificates and trainee
            certificates which are issued by{" "}
            <span className="searchHeadingSpan">DAS Certification Group</span>.
            Please enter your certificate number to search.
          </p>
          <div className="searchInputFil">
            <p className="searchInputFilHeading">CERTIFICATE NUMBER:</p>
            <input
              type="text"
              className="searchInput"
              value={certificateNumber}
              onChange={handleInputChange}
              placeholder="Enter certificate number (e.g., BD-10001-OHSMS)"
              disabled={loading}
            />
            <div className="mainsearchInputButton">
              <button
                className={`searchInputButton ${loading ? "loading" : ""}`}
                onClick={handleSearch}
                disabled={loading || !certificateNumber.trim()}
              >
                {loading ? (
                  <span className="button-content">
                    <span className="spinner"></span>
                    Searching...
                  </span>
                ) : (
                  "Search"
                )}
              </button>
            </div>
          </div>
        </div>

        <div className="heading-bottom">
          <h2 className="heading-bottom-result">SEARCH RESULT</h2>
          {loading ? (
            <div className="loading-container">
              <div className="spinner-large"></div>
            </div>
          ) : (
            <>
              {searchResult && (
                <div className="result-container">
                  <div className="result-field">
                    <span className="field-label">
                      Certificate Registration No:
                    </span>
                    <span className="field-value">
                      {searchResult.certificateNo}
                    </span>
                  </div>
                  <div className="result-field">
                    <span className="field-label">Company Name:</span>
                    <span className="field-value">
                      {searchResult.companyName}
                    </span>
                  </div>
                  <div className="result-field">
                    <span className="field-label">Standard:</span>
                    <span className="field-value">{searchResult.standard}</span>
                  </div>
                  <div className="result-field">
                    <span className="field-label">Accreditation:</span>
                    <span className="field-value">
                      {searchResult.accreditation}
                    </span>
                  </div>
                  <div className="result-field">
                    <span className="field-label">Scope:</span>
                    <span className="field-value">{searchResult.scope}</span>
                  </div>
                  <div className="result-field">
                    <span className="field-label">
                      Initial Certification Date:
                    </span>
                    <span className="field-value">
                      {formatDate(searchResult.initialCertDate)}
                    </span>
                  </div>
                  <div className="result-field">
                    <span className="field-label">Issue Date:</span>
                    <span className="field-value">
                      {formatDate(searchResult.issueDate)}
                    </span>
                  </div>
                  <div className="result-field">
                    <span className="field-label">Expiration Date:</span>
                    <span className="field-value">
                      {formatDate(searchResult.expiryDate)}
                    </span>
                  </div>
                  <div className="result-field">
                    <span className="field-label">Certificate Status:</span>
                    <span className="field-value">{searchResult.status}</span>
                  </div>
                </div>
              )}
              {hasSearched && !searchResult && (
                <div className="no-result">
                  No certificate found with number: {certificateNumber}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <style jsx>{`
        .button-content {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
        }

        .spinner {
          width: 16px;
          height: 16px;
          border: 2px solid #ffffff;
          border-radius: 50%;
          border-top-color: transparent;
          animation: spin 0.8s linear infinite;
        }

        .spinner-large {
          width: 40px;
          height: 40px;
          border: 3px solid #1a76d2;
          border-radius: 50%;
          border-top-color: transparent;
          animation: spin 1s linear infinite;
        }

        .loading-container {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 200px;
        }

        .searchInputButton.loading {
          opacity: 0.7;
          cursor: not-allowed;
        }

        @keyframes spin {
          to {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  );
};

export default CertificateSearch;
