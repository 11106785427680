// src/components/CertificateManagement.jsx
import React, { useState, useEffect } from "react";
import {
  Container,
  Table,
  Button,
  Modal,
  Form,
  Alert,
  Row,
  Col,
} from "react-bootstrap";
import "../styles/CertificateManagement.css";

const CertificateManagement = () => {
  const [certificates, setCertificates] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [formData, setFormData] = useState({
    certificateNo: "",
    companyName: "",
    standard: "",
    accreditation: "",
    scope: "",
    initialCertDate: "",
    issueDate: "",
    expiryDate: "",
    status: "Active",
  });
  const [alert, setAlert] = useState({ show: false, message: "", variant: "" });
  const [loading, setLoading] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL || "https://api.dascertificationgroup.com";
  const token = localStorage.getItem("adminToken");

  useEffect(() => {
    fetchCertificates();
  }, []);

  const fetchCertificates = async () => {
    try {
      const response = await fetch(`${API_URL}/api/certificates`);
      if (!response.ok) throw new Error("Failed to fetch certificates");
      const data = await response.json();
      setCertificates(data);
    } catch (error) {
      showAlert(error.message, "danger");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${API_URL}/api/certificates`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to create certificate");
      }

      await fetchCertificates();
      setShowAddModal(false);
      showAlert("Certificate created successfully", "success");
      resetForm();
    } catch (error) {
      showAlert(error.message, "danger");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(
        `${API_URL}/api/certificates/${selectedCertificate.certificateNo}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to update certificate");
      }

      await fetchCertificates();
      setShowEditModal(false);
      showAlert("Certificate updated successfully", "success");
      resetForm();
    } catch (error) {
      showAlert(error.message, "danger");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}/api/certificates/${selectedCertificate.certificateNo}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to delete certificate");
      }

      await fetchCertificates();
      setShowDeleteModal(false);
      showAlert("Certificate deleted successfully", "success");
    } catch (error) {
      showAlert(error.message, "danger");
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      certificateNo: "",
      companyName: "",
      standard: "",
      accreditation: "",
      scope: "",
      initialCertDate: "",
      issueDate: "",
      expiryDate: "",
      status: "Active",
    });
  };

  const showAlert = (message, variant) => {
    setAlert({ show: true, message, variant });
    setTimeout(() => setAlert({ show: false, message: "", variant: "" }), 3000);
  };

  const standardOptions = [
    "ISO 9001:2015",
    "ISO 14001:2015",
    "ISO 45001:2018",
    "ISO 22000:2018",
  ];

  const statusOptions = ["Active", "Inactive", "Expired", "Suspended"];

  const CertificateForm = ({ onSubmit, submitButtonText }) => (
    <Form onSubmit={onSubmit}>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Certificate Number*</Form.Label>
            <Form.Control
              type="text"
              required
              value={formData.certificateNo}
              onChange={(e) =>
                setFormData({ ...formData, certificateNo: e.target.value })
              }
              placeholder="e.g., BD-10001-OHSMS"
              disabled={submitButtonText.includes("Update")}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Company Name*</Form.Label>
            <Form.Control
              type="text"
              required
              value={formData.companyName}
              onChange={(e) =>
                setFormData({ ...formData, companyName: e.target.value })
              }
              placeholder="Enter company name"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Standard*</Form.Label>
            <Form.Control
              type="text"
              required
              value={formData.standard}
              onChange={(e) =>
                setFormData({ ...formData, standard: e.target.value })
              }
              placeholder="Enter standard (e.g., ISO 9001:2015)"
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Accreditation*</Form.Label>
            <Form.Control
              type="text"
              required
              value={formData.accreditation}
              onChange={(e) =>
                setFormData({ ...formData, accreditation: e.target.value })
              }
              placeholder="e.g., ASiB-UK"
            />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="mb-3">
        <Form.Label>Scope*</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          required
          value={formData.scope}
          onChange={(e) => setFormData({ ...formData, scope: e.target.value })}
          placeholder="Enter certification scope"
        />
      </Form.Group>
      <Row>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>Initial Certification Date*</Form.Label>
            <Form.Control
              type="date"
              required
              value={formData.initialCertDate}
              onChange={(e) =>
                setFormData({ ...formData, initialCertDate: e.target.value })
              }
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>Issue Date*</Form.Label>
            <Form.Control
              type="date"
              required
              value={formData.issueDate}
              onChange={(e) =>
                setFormData({ ...formData, issueDate: e.target.value })
              }
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>Expiry Date*</Form.Label>
            <Form.Control
              type="date"
              required
              value={formData.expiryDate}
              onChange={(e) =>
                setFormData({ ...formData, expiryDate: e.target.value })
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="mb-3">
        <Form.Label>Status*</Form.Label>
        <Form.Select
          required
          value={formData.status}
          onChange={(e) => setFormData({ ...formData, status: e.target.value })}
        >
          {statusOptions.map((status) => (
            <option key={status} value={status}>
              {status}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <div className="d-flex justify-content-end gap-2 mt-4">
        <Button
          variant="secondary"
          onClick={() => {
            resetForm();
            submitButtonText.includes("Update")
              ? setShowEditModal(false)
              : setShowAddModal(false);
          }}
        >
          Cancel
        </Button>
        <Button type="submit" variant="primary" disabled={loading}>
          {loading ? "Processing..." : submitButtonText}
        </Button>
      </div>
    </Form>
  );

  return (
    <Container className="py-4">
      {alert.show && (
        <Alert variant={alert.variant} className="mb-3">
          {alert.message}
        </Alert>
      )}

      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="page-title">Certificate Management</h2>
        <Button variant="primary" onClick={() => setShowAddModal(true)}>
          Add New Certificate
        </Button>
      </div>

      <div className="table-responsive">
        <Table hover bordered className="certificate-table">
          <thead>
            <tr>
              <th>Certificate No</th>
              <th>Company Name</th>
              <th>Standard</th>
              <th>Status</th>
              <th>Expiry Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {certificates.map((cert) => (
              <tr key={cert._id}>
                <td>{cert.certificateNo}</td>
                <td>{cert.companyName}</td>
                <td>{cert.standard}</td>
                <td>
                  <span className={`status-badge status-${cert.status}`}>
                    {cert.status}
                  </span>
                </td>
                <td>{new Date(cert.expiryDate).toLocaleDateString()}</td>
                <td>
                  <Button
                    variant="warning"
                    size="sm"
                    className="me-2"
                    onClick={() => {
                      setSelectedCertificate(cert);
                      setFormData(cert);
                      setShowEditModal(true);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => {
                      setSelectedCertificate(cert);
                      setShowDeleteModal(true);
                    }}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Add Certificate Modal */}
      <Modal
        show={showAddModal}
        onHide={() => {
          resetForm();
          setShowAddModal(false);
        }}
        size="lg"
        className="certificate-modal addModalCSSs"
      >
        <Modal.Header closeButton className="bg-primary text-white">
          <Modal.Title>Add New Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CertificateForm
            onSubmit={handleSubmit}
            submitButtonText="Add Certificate"
          />
        </Modal.Body>
      </Modal>

      {/* Edit Certificate Modal */}
      <Modal
        show={showEditModal}
        onHide={() => {
          resetForm();
          setShowEditModal(false);
        }}
        size="lg"
        className="certificate-modal addModalCSSs"
      >
        <Modal.Header closeButton className="bg-primary text-white">
          <Modal.Title>Edit Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CertificateForm
            onSubmit={handleUpdate}
            submitButtonText="Update Certificate"
          />
        </Modal.Body>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        className="certificate-modal addModalCSSs"
      >
        <Modal.Header closeButton className="bg-danger text-white">
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-0">
            Are you sure you want to delete certificate{" "}
            <strong>{selectedCertificate?.certificateNo}</strong>?
          </p>
          <p className="text-muted mt-2 mb-0">This action cannot be undone.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete} disabled={loading}>
            {loading ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default CertificateManagement;
