import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Blogs from "./Blogs";
import { Link } from "react-router-dom";

const Services = () => {
  const [servicesData, setServicesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const API_URL = "https://api.dascertificationgroup.com";

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(`${API_URL}/data`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setServicesData(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  if (loading) {
    return (
      <div className="text-center p-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger m-3" role="alert">
        Error loading services: {error}
      </div>
    );
  }

  return (
    <div className="services-wrapper">
      <section id="services" className="block services-block">
        <Container fluid className="px-3 px-md-4">
          <div className="title-holder text-success mt-3 text-center mb-3">
            <h2 className="fs-2 fs-md-1">Our services</h2>
            <div className="subtitle fs-6 fs-md-5">services we provide</div>
          </div>
          <Row className="g-4 mt-3 mt-md-5">
            {servicesData.map((service, index) => {
              return (
                <Col xs={12} sm={6} md={4} lg={4} className="holder" key={service.id}>
                  <div className="card shadow h-100 hover-shadow transition">
                    <div 
                      className="overflow" 
                      style={{ 
                        height: '200px',
                        '@media (min-width: 768px)': {
                          height: '250px'
                        },
                        '@media (min-width: 992px)': {
                          height: '300px'
                        }
                      }}
                    >
                      <img
                        src={service.imageUrl}
                        alt={service.heading}
                        className="img-fluid card-img-top"
                        style={{
                          height: '100%',
                          width: '100%',
                          objectFit: 'cover',
                          transition: 'transform 0.3s ease-in-out'
                        }}
                      />
                    </div>
                    <div className="service-text p-3">
                      <h3 className="fs-4 mb-3">{service.heading}</h3>
                      <p className="fs-6 text-muted">{service.description}</p>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>

      <style jsx>{`
        /* Mobile-specific margin top */
        @media (max-width: 767px) {
          .services-wrapper {
            margin-top: 300px;
          }
        }

        .hover-shadow {
          transition: all 0.3s ease-in-out;
        }
        
        .hover-shadow:hover {
          transform: translateY(-5px);
          box-shadow: 0 10px 20px rgba(0,0,0,0.1) !important;
        }

        .hover-shadow:hover img {
          transform: scale(1.05);
        }

        @media (max-width: 576px) {
          .title-holder h2 {
            font-size: 1.8rem;
          }
          .subtitle {
            font-size: 1rem;
          }
          .service-text h3 {
            font-size: 1.2rem;
          }
          .service-text p {
            font-size: 0.9rem;
          }
        }

        @media (min-width: 576px) and (max-width: 767px) {
          .overflow {
            height: 180px !important;
          }
        }

        @media (min-width: 768px) and (max-width: 991px) {
          .overflow {
            height: 220px !important;
          }
        }

        @media (min-width: 992px) {
          .overflow {
            height: 250px !important;
          }
        }
      `}</style>
    </div>
  );
};

export default Services;