import React, { useState, useEffect } from "react";
import {
  Card,
  Container,
  Table,
  Button,
  Form,
  Modal,
  Alert,
} from "react-bootstrap";
import "../styles/Admin.css"; // Make sure to create this CSS file
import CertificateManagement from "./CertificateManagement";

const Admin = () => {
  const [services, setServices] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [formData, setFormData] = useState({
    imageFile: null,
    heading: "",
    description: "",
  });
  const [previewUrl, setPreviewUrl] = useState("");
  const [editingId, setEditingId] = useState(null);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deleteItemName, setDeleteItemName] = useState("");
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const API_URL = "https://api.dascertificationgroup.com";

  // Fetch services data
  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await fetch(`${API_URL}/data`);
      const data = await response.json();
      setServices(data);
    } catch (error) {
      showAlert("danger", "Error fetching services");
    }
  };

  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, imageFile: file });
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setPreviewUrl(fileReader.result);
      };
      fileReader.readAsDataURL(file);
    }
  };

  // Show alert message
  const showAlert = (type, message) => {
    setAlert({ show: true, type, message });
    setTimeout(() => setAlert({ show: false, type: "", message: "" }), 3000);
  };

  // Handle delete click
  const handleDeleteClick = (service) => {
    setDeleteItemId(service._id);
    setDeleteItemName(service.heading);
    setShowDeleteModal(true);
  };

  // Handle delete confirmation
  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      const response = await fetch(`${API_URL}/data/${deleteItemId}`, {
        method: "DELETE",
      });
      if (response.ok) {
        showAlert("success", "Service deleted successfully");
        fetchServices();
        setShowDeleteModal(false);
      }
    } catch (error) {
      showAlert("danger", "Error deleting service");
    }
    setDeleteLoading(false);
  };

  // Handle edit modal open
  const handleEditClick = (service) => {
    setFormData({
      heading: service.heading,
      description: service.description,
      imageFile: null,
    });
    setPreviewUrl(service.imageUrl);
    setEditingId(service._id);
    setShowEditModal(true);
  };

  // Handle update
  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("heading", formData.heading);
      formDataToSend.append("description", formData.description);
      if (formData.imageFile) {
        formDataToSend.append("image", formData.imageFile);
      }

      const response = await fetch(`${API_URL}/data/${editingId}`, {
        method: "PUT",
        body: formDataToSend,
      });

      if (response.ok) {
        showAlert("success", "Service updated successfully");
        setShowEditModal(false);
        fetchServices();
        resetForm();
      }
    } catch (error) {
      showAlert("danger", "Error updating service");
    }
    setLoading(false);
  };

  // Handle add
  const handleAdd = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("heading", formData.heading);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("image", formData.imageFile);

      const response = await fetch(`${API_URL}/data`, {
        method: "POST",
        body: formDataToSend,
      });

      if (response.ok) {
        showAlert("success", "Service added successfully");
        setShowAddModal(false);
        fetchServices();
        resetForm();
      }
    } catch (error) {
      showAlert("danger", "Error adding service");
    }
    setLoading(false);
  };

  // Reset form
  const resetForm = () => {
    setFormData({
      imageFile: null,
      heading: "",
      description: "",
    });
    setPreviewUrl("");
  };

  return (
    <div className="justify-content-center align-items-start min-vh-100 py-4 full-dashboard">
      <Container >
        {alert.show && (
          <Alert variant={alert.type} className="mb-3">
            {alert.message}
          </Alert>
        )}

        <Card className="shadow-sm" style={{ margin: "100px 0 0 0" }}>
          <Card.Header className="d-flex justify-content-between align-items-center bg-primary text-white">
            <h3 className="mb-0">Admin Dashboard</h3>
            <Button variant="light" onClick={() => setShowAddModal(true)}>
              Add New Service
            </Button>
          </Card.Header>
          <Card.Body>
            <Table responsive striped bordered hover className="text-center">
              <thead>
                <tr>
                  <th className="text-center">Image</th>
                  <th className="text-center">Title</th>
                  <th className="text-center">Description</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {services.map((service) => (
                  <tr key={service._id}>
                    <td className="align-middle">
                      <div className="d-flex justify-content-center">
                        <img
                          src={service.imageUrl}
                          alt={service.heading}
                          style={{
                            width: "100px",
                            height: "60px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </td>
                    <td className="align-middle">{service.heading}</td>
                    <td className="align-middle text-start">
                      {service.description.substring(0, 100)}...
                    </td>
                    <td className="align-middle">
                      <div className="d-flex justify-content-center gap-2">
                        <Button
                          variant="warning"
                          size="sm"
                          onClick={() => handleEditClick(service)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDeleteClick(service)}
                        >
                          Delete
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>

        {/* Add Service Modal */}
        <Modal
          show={showAddModal}
          onHide={() => setShowAddModal(false)}
          centered
          className="addModalCSS"
        >
          <Modal.Header closeButton className="bg-primary text-white ">
            <Modal.Title className="w-100 text-center">
              Add New Service
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleAdd} className="px-3">
              <Form.Group className="mb-3">
                <Form.Label className="w-100 text-center">Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleFileChange}
                  required
                  accept="image/*"
                />
                {previewUrl && (
                  <div className="mt-2 text-center">
                    <img
                      src={previewUrl}
                      alt="Preview"
                      style={{
                        maxWidth: "200px",
                        maxHeight: "200px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="w-100 text-center">Title</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.heading}
                  onChange={(e) =>
                    setFormData({ ...formData, heading: e.target.value })
                  }
                  required
                  className="text-center"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="w-100 text-center">
                  Description
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={formData.description}
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                  required
                  className="text-center"
                />
              </Form.Group>
              <div className="d-flex justify-content-center">
                <Button type="submit" disabled={loading}>
                  {loading ? "Adding..." : "Add Service"}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        {/* Edit Service Modal */}
        <Modal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          className="addModalCSS"
          centered
        >
          <Modal.Header closeButton className="bg-primary text-white">
            <Modal.Title className="w-100 text-center">
              Edit Service
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleUpdate} className="px-3">
              <Form.Group className="mb-3">
                <Form.Label className="w-100 text-center">Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleFileChange}
                  accept="image/*"
                />
                {previewUrl && (
                  <div className="mt-2 text-center">
                    <img
                      src={previewUrl}
                      alt="Preview"
                      style={{
                        maxWidth: "200px",
                        maxHeight: "200px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="w-100 text-center">Title</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.heading}
                  onChange={(e) =>
                    setFormData({ ...formData, heading: e.target.value })
                  }
                  required
                  className="text-center"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="w-100 text-center">
                  Description
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={formData.description}
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                  required
                  className="text-center"
                />
              </Form.Group>
              <div className="d-flex justify-content-center">
                <Button type="submit" disabled={loading}>
                  {loading ? "Updating..." : "Update Service"}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          centered
          className="delete-modal addModalCSS"
        >
          <Modal.Header closeButton className="bg-danger text-white">
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center py-4">
            <div className="mb-3">
              <i
                className="fas fa-exclamation-triangle text-danger"
                style={{ fontSize: "3rem" }}
              ></i>
            </div>
            <h5>Are you sure you want to delete this service?</h5>
            <p className="text-muted">"{deleteItemName}"</p>
            <p className="text-danger mb-0">This action cannot be undone.</p>
          </Modal.Body>
          <Modal.Footer className="justify-content-center border-0 pt-0">
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
              className="px-4"
              disabled={deleteLoading}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={handleDelete}
              className="px-4"
              disabled={deleteLoading}
            >
              {deleteLoading ? "Deleting..." : "Delete"}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
      <CertificateManagement/>
    </div>
  );
};

export default Admin;
