import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/images/logo2.png";
import { Link, NavLink } from "react-router-dom";
import Head from "./Head";

const Header = () => {
  return (
    <>
      <Head />
      <Navbar bg="light" expand="lg" className="sticky-navbar">
        <Container className="headerMainNav py-2">
          <Navbar.Brand as={Link} to="/">
            <img 
              src={logo} 
              alt="Company Logo" 
              className="headerImage"
            />
          </Navbar.Brand>
          <Navbar.Toggle 
            aria-controls="basic-navbar-nav" 
            className="custom-toggler"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto nav-links">
              <Nav.Link as={Link} to="/#home" className="MainNavbar">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="about" className="MainNavbar">
                About
              </Nav.Link>
              <Nav.Link as={Link} to="Training" className="MainNavbar">
                Services
              </Nav.Link>
              <Nav.Link as={Link} to="Accredation" className="MainNavbar">
                Accredation
              </Nav.Link>
              <Nav.Link as={Link} to="policy" className="MainNavbar">
                Policy
              </Nav.Link>
              <Nav.Link as={Link} to="contact" className="MainNavbar">
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <style jsx>{`
        .sticky-navbar {
          position: sticky;
          top: 0;
          z-index: 1000;
          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }

        .headerImage {
          max-height: 50px;
          width: auto;
          transition: all 0.3s ease;
        }

        .MainNavbar {
          color: #333;
          font-weight: 500;
          padding: 0.5rem 1rem !important;
          margin: 0 0.2rem;
          transition: all 0.3s ease;
          position: relative;
        }

        .MainNavbar:hover {
          color: #0d6efd;
        }

        .MainNavbar::after {
          content: '';
          position: absolute;
          width: 0;
          height: 2px;
          bottom: 0;
          left: 50%;
          background-color: #0d6efd;
          transition: all 0.3s ease;
          transform: translateX(-50%);
        }

        .MainNavbar:hover::after {
          width: 100%;
        }

        .custom-toggler {
          border: none;
          padding: 0.25rem 0.5rem;
        }

        .custom-toggler:focus {
          box-shadow: none;
          outline: none;
        }

        /* Mobile Styles */
        @media (max-width: 991px) {
          .headerMainNav {
            padding: 0.5rem 1rem;
          }

          .headerImage {
            max-height: 40px;
          }

          .nav-links {
            padding: 1rem 0;
          }

          .MainNavbar {
            padding: 0.75rem 1rem !important;
            border-bottom: 1px solid #eee;
          }

          .MainNavbar:last-child {
            border-bottom: none;
          }

          .MainNavbar::after {
            display: none;
          }

          .MainNavbar:hover {
            background-color: #f8f9fa;
          }
        }

        /* Tablet Styles */
        @media (min-width: 992px) and (max-width: 1199px) {
          .MainNavbar {
            padding: 0.5rem 0.75rem !important;
            font-size: 0.9rem;
          }

          .headerImage {
            max-height: 45px;
          }
        }

        /* Desktop Styles */
        @media (min-width: 1200px) {
          .headerMainNav {
            padding: 0.5rem 2rem;
          }

          .nav-links {
            gap: 0.5rem;
          }
        }

        /* Animation for Navbar Collapse */
        .navbar-collapse {
          transition: all 0.3s ease;
        }

        .navbar-collapse.show {
          animation: slideDown 0.3s ease forwards;
        }

        @keyframes slideDown {
          from {
            opacity: 0;
            transform: translateY(-10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </>
  );
};

export default Header;