import React from "react";
import { Carousel } from "react-bootstrap";

const heroData = [
  {
    id: 1,
    image: require("../assets/images/slide01.jpg"),
    title: "Accredited Certification ",
    description: "ISO 9001, ISO 14001, ISO 22000, ISO 45001, ISO 27001",
    link: "/CertificateSearch",
  },
  {
    id: 2,
    image: require("../assets/images/slide02.jpg"),
    title: "We are hiring auditors worldwide",
    description: "Global Partnership Opportunity Available.",
    link: "/CertificateSearch",
  },
  {
    id: 3,
    image: require("../assets/images/slide03.jpg"),
    title: "Sustainability through",
    description: "Continual Improvement",
    link: "/CertificateSearch",
  },
];

const Banner = () => {
  return (
    <section 
      id="home" 
      className="hero-block md:mt-0 mt-24" // Using Tailwind's responsive classes
    >
      <Carousel>
        {heroData.map((hero) => {
          return (
            <Carousel.Item key={hero.id}>
              <img
                className="d-block w-full"
                src={hero.image}
                alt={"slide " + hero.id}
              />
              <Carousel.Caption>
                <h1 className="caption-text">{hero.title}</h1>
                <h2>{hero.description}</h2>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </section>
  );
};

export default Banner;