import React from "react";
import { Link } from "react-router-dom";

const Head = () => {
  return (
    <>
      <section className="head">
        <div className="container flexSB top-nav">
          <div className="logo">
            <span className="company-name">DAS Certification Group</span>
          </div>

          <div className="email d-none d-md-block">
            <span>info@dascertificationgroup.com</span>
          </div>

          <div className="social-buttons">
            <button className="btn btn-primary">Get Quote</button>
            <Link to='CertificateSearch' className="btn btn-primary">Verification</Link>
          </div>
        </div>
      </section>

      <style jsx>{`
        .head {
          background: #000;
          padding: 15px 0;
          color:#fff;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          margin-top: -15px
        }

        .top-nav {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          gap: 15px;
        }

        .company-name {
          font-weight: 600;
          font-size: 1.2rem;
          color:#fff;
        }

        .email span {
          color:#fff;
        }

        .social-buttons {
          display: flex;
          gap: 10px;
        }

        .btn {
          padding: 8px 16px;
          font-size: 0.9rem;
          white-space: nowrap;
        }

        /* Mobile Styles */
        @media (max-width: 767px) {
          .top-nav {
            flex-direction: column;
            text-align: center;
          }

          .social-buttons {
            width: 100%;
            justify-content: center;
            margin-top: 10px;
          }

          .company-name {
            font-size: 1.1rem;
          }

          .btn {
            padding: 6px 12px;
            font-size: 0.85rem;
          }
        }

        /* Tablet Styles */
        @media (min-width: 768px) and (max-width: 991px) {
          .top-nav {
            padding: 0 15px;
          }

          .company-name {
            font-size: 1rem;
          }

          .email span {
            font-size: 0.9rem;
          }

          .btn {
            padding: 7px 14px;
            font-size: 0.85rem;
          }
        }

        /* Larger Screens */
        @media (min-width: 992px) {
          .top-nav {
            padding: 0 30px;
          }

          .btn:hover {
            transform: translateY(-2px);
            transition: transform 0.2s ease;
          }
        }
      `}</style>
    </>
  );
};

export default Head;